<template>
  <div class="bg-gray-200 pt-10vh sm:pt-24">
    <div class=" my-2 sm:my-6 text-2xl sm:text-4xl text-center font-500 text-blue-800">Affiliate account registration</div>
    <div class=" text-base sm:text-22px font-500  text-blue-800 text-center">Contact Information</div>
    <div class="form_container">
      <a-form-model
      ref="ruleForm"
      :rules="rules"
      layout="horizontal"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      >
    <div>
    <a-form-model-item class=" w-full" prop="Surname" required label="Surname:">
      <a-input v-model="form.Surname" placeholder="Surname" />
    </a-form-model-item>
    <a-form-model-item class=" w-full" prop="Forename" required label="Forename(s)">
      <a-input v-model="form.Forename" placeholder="Forename(s)" />
    </a-form-model-item>
    <a-form-model-item class=" w-full"  label="Maiden Name">
      <a-input v-model="form.MaidenName" placeholder="Maiden Name if applicable" />
    </a-form-model-item>
    <a-form-model-item class=" w-full" label="Preferred Name">
      <a-input v-model="form.PreferredName" placeholder="Preferred Name if applicable" />
    </a-form-model-item>
    <a-form-model-item class="" prop="Title" required label="Title">
      <a-input v-model="form.Title" placeholder="Title" />
    </a-form-model-item>
    <a-form-model-item :wrapper-col='inlineStyle' prop="Gender" required label="Gender:">
      <a-radio-group v-model="form.Gender">
        <a-radio value="1">
          Male
        </a-radio>
        <a-radio value="2">
          Female
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item :wrapper-col='inlineStyle' prop="nz_taxer" required label="NZ Tax Resident">
      <a-radio-group v-model="form.nz_taxer">
        <a-radio value="true">
          Yes
        </a-radio>
        <a-radio value="false">
          No
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item v-if="form.nz_taxer == 'true'" prop="nz_ird" required label="NZ Ird Num:">
      <a-input v-model="form.nz_ird"  placeholder="NZ IRD Number" />
    </a-form-model-item>
    <a-form-model-item :wrapper-col='inlineStyle' prop="gst_registered" required label="GST Registered">
      <a-radio-group v-model="form.gst_registered">
        <a-radio value="true">
          Yes
        </a-radio>
        <a-radio value="false">
          No
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item v-if="form.gst_registered == 'true'" prop="gst_number" required label="GST Registered">
      <a-input v-model="form.gst_number" placeholder="GST_Registered" />
    </a-form-model-item>
    <a-form-model-item required prop="dob" label="Date of birth">
      <a-date-picker
        v-model="form.dob"
        type="date"
        placeholder="Pick a date"
        style="width:100%"
      />
    </a-form-model-item>
    <a-form-model-item prop="home_address" required label="Home Address">
      <a-input v-model="form.home_address" placeholder="Home Address" />
    </a-form-model-item>
    <a-form-model-item prop="home_tel" required label="Home Telephone">
      <a-input v-model="form.home_tel" placeholder="Home Telephone" />
    </a-form-model-item>
    <a-form-model-item prop="mob_phone" required label="Mobile Phone">
      <a-input v-model="form.mob_phone" placeholder="Mobile Phone" />
    </a-form-model-item>
    <a-form-model-item prop="email" required label="Email Address">
      <a-input v-model="form.email" placeholder="Email Address" />
    </a-form-model-item>
    <a-form-model-item :wrapper-col='btnStyle'>
      <a-button @click="onSubmit" style="width:50%" type="primary">
        Submit
      </a-button>
    </a-form-model-item>
    </div>
  </a-form-model>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import BaseImg from '@/components/BaseImg.vue'

export default {
  methods: {
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          return false
        }
      })
    }
  },
  data () {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      inlineStyle: {
        span: 12, offset: 1
      },
      btnStyle: {
        span: 24, offset: 7
      },
      form: {
        Surname: '',
        Forename: '',
        MaidenName: '',
        PreferredName: '',
        Title: '',
        Gender: null,
        nz_taxer: null,
        nz_ird: '',
        gst_registered: null,
        gst_number: '',
        dob: undefined,
        home_address: '',
        home_tel: '',
        mob_phone: '',
        email: ''
      },
      rules: {
        Surname: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        Forename: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        Title: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        Gender: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        nz_taxer: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        nz_ird: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        gst_registered: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        gst_number: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        dob: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        home_address: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        home_tel: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        mob_phone: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {

    ...mapGetters('layout', ['GetScreenStyle'])
  }
}
</script>
<style>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}
.form_container{
  max-width: 500px;
  margin: 1rem auto 0;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 768px) {
  .form_container{
    padding: 5vw;
}
}
</style>
